import CodeMirror from 'codemirror/lib/codemirror.js'
import $ from 'jquery'

import 'codemirror/mode/haml/haml.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/javascript/javascript.js'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/seti.css'

$(document).ready(function() {
  $('textarea[data-js-codemirror-editable]').each(function() {
    return CodeMirror.fromTextArea(this, {
      readOnly: this.attributes['readonly'],
      lineNumbers: !this.attributes['readonly'],
      mode: 'haml',
      tabSize: 2,
      indentWithTabs: true,
      smartIndent: false,
      theme: 'seti'
    })
  })
})
